<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      Laatste reviews
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-main>
      <ReviewCard/> >
      <ReviewCard/> >
      <ReviewCard/> >
    </v-main>
  </v-app>
</template>

<script>
import ReviewCard from './components/Review';

export default {
  name: 'App',

  components: {
    ReviewCard,
  },

  data: () => ({
    //
  }),
};
</script>
